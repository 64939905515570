<template>
  <div id="professionals-tiss">
    <p class="tab-description">Profissionais que atendem essa operadora</p>

    <div class="cards-container">
      <div
        id="professional-container"
        v-for="(doctor, index) in doctors"
        :key="index"
      >
        <div class="professional-container">
          <div class="container-content">
            <div class="container-header">
              <div class="professional-description">
                <Avatar 
                  size="40"
                  :src="doctor?.picture"                 
                />
                <p v-if="['DOCTOR_MANAGER', 'DOCTOR', 'DOCTOR_ANESTHETIST'].includes(doctor.type)">Dr(a) {{ doctor.name }}</p>
                <p v-else-if="doctor.type === 'NURSE'">Enfermeiro(a) {{ doctor.name }}</p>
                <p v-else-if="doctor.type === 'TECHNICIAN'">Técnico(a) {{ doctor.name }}</p>
                <p v-else-if="doctor.type === 'TECHNOLOGIST'">Tecnólogo(a) {{ doctor.name }}</p>
                <p v-else> {{ doctor.name }}</p>
              </div>

              <div class="toggle-container">
                <label class="toggle-subtitle" :for="`doctor-${index}-active`">
                  {{ doctor.active ? 'Ativo' : 'Inativo' }}
                </label>
                <toggle-button
                  :id="`doctor-${index}-active`"
                  :width="40"
                  :height="24"
                  :value="doctor.active"
                  @change="setDoctorActive(index)"
                  :color="{ checked: '#00C773' }"
                />
              </div>
            </div>

            <div
              class="container-footer"
              :class="{ hide: !doctors[index].active }"
            >
              <hr />

              <div class="footer-content">
                <div class="first-grid">
                  <div>
                    <label :for="`doctor-grade-${index}`">Grau</label>
                    <multiselect
                      :id="`doctor-grade-${index}`"
                      v-model="doctor.tiss_professional_setting.grade"
                      placeholder="Selecionar"
                      :options="grades"
                      track-by="value"
                      label="label"
                      :allow-empty="false"
                      :showLabels="false"
                      :showNoResults="false"
                      class="with-border"
                    >
                      <template slot="caret">
                        <div class="chevron">
                          <ChevronDown />
                        </div>
                      </template>
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.label }}
                      </template>
                      <template slot="noOptions"> Nenhuma opção </template>
                      <template slot="noResult"> Nenhum resultado </template>
                    </multiselect>
                    <div
                      v-if="
                        validated && !doctor.tiss_professional_setting.grade
                      "
                      class="custom-invalid-feedback"
                    >
                      Campo obrigatório
                    </div>
                  </div>

                  <div>
                    <label :for="`doctor-code_type-type-${index}`">
                      Tipo de código
                    </label>
                    <multiselect
                      :id="`doctor-code_type-${index}`"
                      v-model="doctor.tiss_professional_setting.code_type"
                      placeholder="Selecionar"
                      :options="code_types"
                      track-by="value"
                      label="label"
                      :allow-empty="false"
                      :showLabels="false"
                      :showNoResults="false"
                      class="with-border"
                    >
                      <template slot="caret">
                        <div class="chevron">
                          <ChevronDown />
                        </div>
                      </template>
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.label }}
                      </template>
                      <template slot="noOptions"> Nenhuma opção </template>
                      <template slot="noResult"> Nenhum resultado </template>
                    </multiselect>
                    <div
                      v-if="
                        validated && !doctor.tiss_professional_setting.code_type
                      "
                      class="custom-invalid-feedback"
                    >
                      Campo obrigatório
                    </div>
                  </div>

                  <div>
                    <div class="input-container">
                      <label :for="`doctor-grade-${index}`">
                        Cód. Operadora / CPF
                      </label>
                      <b-input
                        :id="`doctor-operator_code-${index}`"
                        autocomplete="off"
                        placeholder="Descrever"
                        @input="el => onlyNumbers(el, index)"
                        v-model="doctor.tiss_professional_setting.operator_code"
                        :key="doctor.id"
                      />
                      <div
                        v-if="
                          validated &&
                          !doctor.tiss_professional_setting.operator_code
                        "
                        class="custom-invalid-feedback"
                      >
                        Campo obrigatório
                      </div>
                    </div>
                  </div>
                </div>

                <div class="second-grid">
                  <div class="input-container">
                    <label :for="`doctor-name-${index}`">
                      Nome do profissional
                    </label>
                    <b-input
                      :id="`doctor-name-${index}`"
                      autocomplete="off"
                      :v-model="doctor.name"
                      :placeholder="doctor.name"
                      readonly
                    />
                    <div
                      v-if="validated && !doctor.name"
                      class="custom-invalid-feedback"
                    >
                      Campo obrigatório
                    </div>
                  </div>

                  <div>
                    <label :for="`doctor-council-${index}`">Conselho</label>
                    <multiselect
                      :id="`doctor-council-${index}`"
                      v-model="doctor.tiss_professional_setting.council"
                      placeholder="Selecionar"
                      :options="councils"
                      track-by="value"
                      label="label"
                      :allow-empty="false"
                      :showLabels="false"
                      :showNoResults="false"
                      class="with-border"
                    >
                      <template slot="caret">
                        <div class="chevron">
                          <ChevronDown />
                        </div>
                      </template>
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.label }}
                      </template>
                      <template slot="noOptions"> Nenhuma opção </template>
                      <template slot="noResult"> Nenhum resultado </template>
                    </multiselect>
                    <div
                      v-if="
                        validated && !doctor.tiss_professional_setting.council
                      "
                      class="custom-invalid-feedback"
                    >
                      Campo obrigatório
                    </div>
                  </div>
                </div>

                <div class="third-grid">
                  <div class="input-container">
                    <label :for="`doctor-professional_number-${index}`">
                      Número do profissional
                    </label>
                    <b-input
                      :id="`professional-number-${index}`"
                      autocomplete="off"
                      placeholder="Descrever"
                      v-model="
                        doctor.tiss_professional_setting.professional_number
                      "
                    />
                    <div
                      v-if="
                        validated &&
                        !doctor.tiss_professional_setting.professional_number
                      "
                      class="custom-invalid-feedback"
                    >
                      Campo obrigatório
                    </div>
                  </div>

                  <div>
                    <label :for="`doctor-state-${index}`">UF</label>
                    <multiselect
                      :id="`doctor-state-${index}`"
                      v-model="doctor.tiss_professional_setting.state"
                      placeholder="Selecionar"
                      :options="states"
                      track-by="value"
                      label="label"
                      :allow-empty="false"
                      :showLabels="false"
                      :showNoResults="false"
                      class="with-border"
                    >
                      <template slot="caret">
                        <div class="chevron">
                          <ChevronDown />
                        </div>
                      </template>
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.label }}
                      </template>
                      <template slot="noOptions"> Nenhuma opção </template>
                      <template slot="noResult"> Nenhum resultado </template>
                    </multiselect>
                    <div
                      v-if="
                        validated && !doctor.tiss_professional_setting.state
                      "
                      class="custom-invalid-feedback"
                    >
                      Campo obrigatório
                    </div>
                  </div>

                  <div class="cbo-input">
                    <label :for="`doctor-cbo-${index}`">CBO</label>
                    <multiselect
                      :id="`doctor-cbo-${index}`"
                      v-model="doctor.tiss_professional_setting.cbo"
                      placeholder="Selecionar"
                      :options="cbos"
                      track-by="value"
                      label="label"
                      :allow-empty="false"
                      :showLabels="false"
                      :showNoResults="false"
                      class="inline-text-container with-border"
                    >
                      <template slot="caret">
                        <div class="chevron">
                          <ChevronDown />
                        </div>
                      </template>
                      <template slot="singleLabel" slot-scope="{ option }">
                        <span
                          class="d-inline-block text-truncate width-85 inline-text-input"
                        >
                          {{ option.label }}
                        </span>
                      </template>
                      <template slot="noOptions"> Nenhuma opção </template>
                      <template slot="noResult"> Nenhum resultado </template>
                    </multiselect>
                    <div
                      v-if="validated && !doctor.tiss_professional_setting.cbo"
                      class="custom-invalid-feedback"
                    >
                      Campo obrigatório
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  props: {
    tiss: Object,
    validated: Boolean,
    doctorsProps: Array
  },
  components: {
    Avatar: () => import('@/components/General/Avatar.vue'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  data: () => ({
    clinic: getCurrentClinic(),
    states: require('@/components/Tiss/Data/statesUf.json'),
    cbos: require('@/components/Tiss/Data/cbos.json'),
    grades: require('@/components/Tiss/Data/grades.json'),
    code_types: require('@/components/Tiss/Data/contractedCodeTypes.json'),
    councils: require('@/components/Tiss/Data/professionalCouncils.json'),
    doctors: [],
  }),
  mounted() {
    if (!this.doctors.length) {
      this.fillDoctors()
    }
  },
  computed: {
    computedDoctors() {
      if (!this.doctors) return

      return this.doctors.reduce((acc, current) => {
        const element = acc.find(item => item.person_id === current.person_id)
        if (!element) {
          return acc.concat([current])
        }
        return acc
      }, [])
    }
  },
  methods: {
    onlyNumbers(el, index){
      if(!isNaN(parseFloat(el)) && isFinite(el)) return

      this.doctors[index].tiss_professional_setting.operator_code = ' '
      setTimeout(() => {
        this.doctors[index].tiss_professional_setting.operator_code = el.replace(/\D/g, '')
      }, "100")
    },
    setFields() {
      const isLoading = this.$loading.show()
      this.doctors.forEach(element => {
        element.tiss_professional_setting.council = this.councils.find(
          option => option.value === element.tiss_professional_setting.council
        )
        element.tiss_professional_setting.code_type = this.code_types.find(
          option => option.value === element.tiss_professional_setting.code_type
        )
        element.tiss_professional_setting.grade = this.grades.find(
          option => option.value === element.tiss_professional_setting.grade
        )
        element.tiss_professional_setting.state = this.states.find(
          option => option.value === element.tiss_professional_setting.state
        )
        element.tiss_professional_setting.cbo = this.cbos.find(
          option => option.value === element.tiss_professional_setting.cbo
        )
      })
      isLoading.hide()
    },
    setDoctorActive(index) {
      this.doctors[index].active = !this.doctors[index].active
    },
    async getAllProfessionals() {
      const isLoading = this.$loading.show()
      this.doctors = []
      try {
        const { data } = await this.api.getAllProfessionalsWithTissSettings(
          this.clinic.id,
          this.tiss.invoicing_tiss_setting_id
        )
        data.map(professional => {
          this.doctors.push({
            person_id: professional.person_id,
            name: professional.name,
            active: professional.active,
            avatar: professional.picture,
            type: professional.type,
            tiss_professional_setting: {
              cbo: professional.cbo,
              code_type: professional.code_type,
              council: professional.council,
              grade: professional.grade,
              operator_code: professional.operator_code,
              professional_number: professional.professional_number,
              state: professional.state
            }
          })
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async fillDoctors() {
      await this.getAllProfessionals()

      this.setFields()
      this.$emit('update-doctors', this.doctors)
    }
  },
  beforeDestroy() {
    this.$emit('update-doctors', this.computedDoctors)
  },
  watch: {
    'doctors': {
      async handler(value) {
        this.$emit('update-doctors', this.computedDoctors)
      },
      deep: true,
    },
    'tiss.invoicing_tiss_setting_id': {
      async handler(value) {
        if (this.doctorsProps.length) {
          this.doctors = this.doctorsProps
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
#professionals-tiss {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: 'Nunito Sans';
  font-style: normal;
  color: var(--type-active);
  margin-bottom: 30px;

  .tab-description {
    margin-top: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  .inline-text-container {
    width: 45ch;
  }

  .inline-text-input {
    width: 40ch;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: auto;
    max-height: 65vh;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: var(--neutral-300) var(--neutral-000);
  }

  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: var(--neutral-000);
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--neutral-300);
    border-radius: 20px;
    border: 3px solid var(--neutral-000);
  }
}

#professional-container {
  .professional-container {
    border: 1px solid var(--neutral-300);
    border-radius: 16px;

    input {
      height: 38px !important;
    }

    .container-content {
      padding: 24px;

      .hide {
        display: none;
      }

      .container-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .professional-description {
          display: flex;
          align-items: center;
          gap: 16px;

          p {
            font-weight: 700;
            font-size: 18px;
            line-height: 134%;
          }
        }

        .toggle-container {
          display: flex;
          align-items: center;
          gap: 8px;

          .toggle-subtitle {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: var(--dark-blue);
            margin: 0;
          }
        }
      }

      .container-footer {
        hr {
          margin: 24px 0;
          background: var(--neutral-300);
        }

        .footer-content {
          display: flex;
          flex-direction: column;
          gap: 16px;

          label {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: var(--dark-blue);
          }

          .inline-text {
            span {
              width: 30ch;
              text-overflow: ellipsis;
              word-wrap: unset;
              white-space: nowrap;
              overflow: hidden;
            }
          }

          .first-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;
          }

          .second-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 8px;
          }

          .third-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;
          }

          .input-container {
            display: flex;
            flex-direction: column;

            input[type='text'] {
              border: 1px solid var(--neutral-300);
              border-radius: 8px;
              outline: none;
              padding: 16px;

              &:focus {
                border: 1px solid var(--blue-500);
              }
            }
          }
        }
      }
    }
  }
}
</style>
